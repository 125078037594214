import React, { FunctionComponent, ReactNode } from 'react';
import { Container, Card } from 'mdlkit';

const AppCard: FunctionComponent = ({ children }: { children?: ReactNode }) => (
  <Container maxWidth={520}>
    <Card py="md" px="sm" boxShadowSize="lg" borderWidth={0}>
      {children}
    </Card>
  </Container>
);

export default AppCard;
