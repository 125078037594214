import React, { PureComponent } from 'react';
import { NextPageContext } from 'next';
import nextCookie from 'next-cookies';
import Router from 'next/router';
import cookie from 'js-cookie';
import { SsoToken, Auth } from '../interfaces/auth';
import { SIGN_IN_URL } from '../constants/login';

interface LoginParams {
  jwt: string;
  redirectUrl: string;
  ssoToken: SsoToken;
  userId: string;
}

interface AuthenticateProps {
  auth?: Auth;
}

export type UserId = number;

export type JwtToken = string;

export const login = async ({
  ssoToken,
  jwt,
  userId,
  redirectUrl,
}: LoginParams) => {
  const in1Hour = 1 / 24;
  cookie.set('auth', JSON.stringify({ sso: ssoToken, userId, jwt }), {
    expires: in1Hour,
  });
  Router.push(redirectUrl);
};

export const getAuth = (ctx?: NextPageContext): AuthenticateProps => {
  let authCookie: string;
  if (ctx) {
    // server
    const { auth } = nextCookie(ctx);
    authCookie = auth || '';
  } else {
    // client
    authCookie = cookie.get('auth');
  }

  if (!authCookie) {
    return { auth: undefined };
  }

  return { auth: JSON.parse(authCookie) };
};

export const getToken = (ctx?: NextPageContext): JwtToken => {
  const { auth } = getAuth(ctx);
  return auth?.jwt || '';
};

export const getUserId = (ctx?: NextPageContext): UserId => {
  const { auth } = getAuth(ctx);
  return auth?.userId || 0;
};

const getDisplayName = (Component) =>
  Component.displayName || Component.name || 'Component';

export const authenticate = (ctx: NextPageContext): AuthenticateProps => {
  const { auth } = getAuth(ctx);
  const hasToken = Boolean(auth);

  // server redirect
  if (ctx.res && !hasToken) {
    ctx.res.writeHead(302, { Location: SIGN_IN_URL });
    ctx.res.end();
  }

  // client redirect
  if (!hasToken) {
    Router.push(SIGN_IN_URL);
  }

  return { auth };
};

export const withAuthSync = (WrappedComponent) =>
  class extends PureComponent {
    static async getInitialProps(ctx) {
      const { auth } = authenticate(ctx);

      const componentProps =
        WrappedComponent.getInitialProps &&
        (await WrappedComponent.getInitialProps(ctx));

      return { ...componentProps, auth };
    }

    static displayName = `withAuthSync(${getDisplayName(WrappedComponent)})`;

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
