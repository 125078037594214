import moment from 'moment';

export const normalizeDate = (value: string): string => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
  }
  return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}/${onlyNums.slice(
    4,
    8
  )}`;
};

export const formatDate = (value: string, type?: string): string => {
  const formattedDate = moment(new Date(value)).format(
    type === 'iso' ? 'YYYY-MM-DD' : 'MM/DD/YYYY'
  );

  return formattedDate.toLowerCase() !== 'invalid date' ? formattedDate : '';
};

export const normalizeNumbersOnly = (value: string): string =>
  value.toString().replace(/[^0-9]/g, '');
